function updateCopyright() {
  var el = document.getElementById('#copyright-year');
  if (!el) return;
  el.textContent = new Date().getFullYear();
}

function connectScrollFade() {
  if (typeof IntersectionObserver === 'undefined') {
    return;
  }

  var intersectionObserver = new IntersectionObserver(entries => {
    entries.forEach((entry, idx) => {
      if (entry.isIntersecting) {
        // staggers simultaneous reveals
        setTimeout(() => entry.target.classList.add('fade-in'), idx * 250);
      }
    });
  });
  var els = document.getElementsByClassName('fade');
  for (el of els) {
    el.classList.add('fade-out');
    intersectionObserver.observe(el);
  }
}

updateCopyright();
connectScrollFade();
